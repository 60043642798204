import { IEntityInfo } from '@/models/akeneoSync.model';

import { renderDate } from '@/utils';
import { TableColumnsType } from 'antd';
import { TFunction } from 'i18next';
import { DateFormats } from '@/constants/dates';

export const getSyncTableConfig = (t: TFunction): TableColumnsType<IEntityInfo> => [
  {
    title: t('label_entity'),
    dataIndex: 'entityType',
    key: 'entityType',
    render: (entityType: string) => t(entityType),
  },
  {
    title: t('label_updated_at'),
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    render: (updatedAt: string) => renderDate(updatedAt, DateFormats.DATE_WITH_TIME),
  },
];
