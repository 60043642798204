import {
  IGetCategoriesDTO,
  staticCategoriesEndpoints,
  IGetCategoriesResponse,
  IGetCategoriesTreeDTO,
  IUpdateCategoryDTO,
  getCategoryEndpoints,
} from '@/models/category.model';
import { ApiResponse, convertModelToFormData, httpClient } from '@/utils';

class CategoryAPI {
  static getCategories(params: IGetCategoriesDTO): ApiResponse<IGetCategoriesResponse> {
    const { GET_CATEGORIES } = staticCategoriesEndpoints;
    return httpClient.get<IGetCategoriesResponse>(GET_CATEGORIES, {
      params,
    });
  }

  static getCatagoriesTree(params: IGetCategoriesTreeDTO): ApiResponse<IGetCategoriesResponse> {
    const { GET_CATEGORIES_TREE } = staticCategoriesEndpoints;
    return httpClient.get<IGetCategoriesResponse>(GET_CATEGORIES_TREE, {
      params,
    });
  }

  static updateCategory({ id, ...data }: IUpdateCategoryDTO): ApiResponse<IGetCategoriesResponse> {
    const { UPDATE_CATEGORY } = getCategoryEndpoints({ id });
    const formData = convertModelToFormData(data, new FormData(), '', true, true);
    return httpClient.patch<IGetCategoriesResponse>(UPDATE_CATEGORY, formData);
  }
}

export default CategoryAPI;
