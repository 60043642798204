import { RootState } from '@/store';
import { AkeneoSyncState, SLICE_NAME } from './models';
import { createSelector } from '@reduxjs/toolkit';

const akeneoSyncSelector = (state: RootState): AkeneoSyncState => state[SLICE_NAME];

export const selectEntities = createSelector(akeneoSyncSelector, (state: AkeneoSyncState) => state.entities);

export const selectIsLoading = createSelector(akeneoSyncSelector, (state: AkeneoSyncState) => state.isLoading);

export const selectError = createSelector(akeneoSyncSelector, (state: AkeneoSyncState) => state.error);
