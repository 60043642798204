import { TreeProps } from 'antd';
import { StyledTree } from './TreeStyles';
import { ChevronIcon } from '@/icons';

export type ITreeProps = TreeProps;
export type TreeDataNode = TreeProps['treeData'];

const Tree = (props: ITreeProps) => <StyledTree switcherIcon={<ChevronIcon />} {...props} />;

export default Tree;
