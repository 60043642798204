// Globals
import { useTranslation } from 'react-i18next';

// Modules
import { getCategoriesOptionsConfig } from '@/modules/Categories';

// Components
import { Form, FormItem, Modal, ModalSize } from '@/components';
import { Button, Checkbox, Input, Select, TextEditor, useVariableDetector, VariableDetector, VariableType } from '@/ui';
import { StyledFormItem } from './FaqFormStyles';

// Helpers
import { FaqFieldsNames, mapCategoriesTreeToArray, TFaqFormValues } from '../../helpers';
import { useFaqFormRules } from '../../hooks';

// Models
import { ICategory } from '@/models/category.model';

type IFaqFormProps = {
  onSubmit?: (values: TFaqFormValues) => void;
  initialValues?: Partial<TFaqFormValues>;
  isLoading?: boolean;
  isOpen?: boolean;
  toggleOpen: () => void;
  category?: ICategory;
  isEdit?: boolean;
};

const FaqForm = ({
  initialValues,
  isLoading,
  onSubmit,
  isOpen = false,
  toggleOpen,
  category,
  isEdit = false,
}: IFaqFormProps) => {
  const { t } = useTranslation();
  const rules = useFaqFormRules();
  const { variables, selectVariableInInput, registerInputRef, handleInputChange } = useVariableDetector();

  return (
    <Modal
      visible={isOpen}
      onOk={toggleOpen}
      title={t(isEdit ? 'edit_title_faq' : 'create_title_faq')}
      footer={null}
      destroyOnClose
      onCancel={toggleOpen}
      onClose={toggleOpen}
      size={ModalSize.LARGE}
    >
      <VariableDetector variables={variables} onSelectVariable={selectVariableInInput} />
      <Form onFinish={onSubmit} initialValues={initialValues}>
        <FormItem name={FaqFieldsNames.QUESTION} rules={rules[FaqFieldsNames.QUESTION]} label={t('label_question_one')}>
          <Input
            ref={(ref) => registerInputRef(t('label_question_one'), ref, VariableType.INPUT)}
            onChange={(e) => handleInputChange(t('label_question_one'), e.target.value)}
          />
        </FormItem>
        <FormItem name={FaqFieldsNames.ANSWER} rules={rules[FaqFieldsNames.ANSWER]} label={t('label_answer_one')}>
          <TextEditor
            ref={(ref) => registerInputRef(t('label_answer_one'), ref, VariableType.INPUT)}
            onChange={(e) => handleInputChange(t('label_answer_one'), e)}
            placeholder={t('label_answer_one')}
          />
        </FormItem>
        <FormItem
          name={FaqFieldsNames.CATEGORY_CODES}
          rules={rules[FaqFieldsNames.CATEGORY_CODES]}
          label={t('label_categories')}
        >
          {!!category && (
            <Select
              options={getCategoriesOptionsConfig(mapCategoriesTreeToArray(category?.children ?? []))}
              isMultiselect
            />
          )}
        </FormItem>
        <StyledFormItem
          layout="horizontal"
          name={FaqFieldsNames.IS_ACTIVE}
          valuePropName="checked"
          label={t('label_status')}
        >
          <Checkbox />
        </StyledFormItem>
        <Button type="primary" htmlType="submit" block loading={isLoading}>
          {t(isEdit ? 'edit' : 'create_btn')}
        </Button>
      </Form>
    </Modal>
  );
};

export default FaqForm;
