import { COLORS, pickThemeFontStyles } from '@/theme';
import { Upload } from 'antd';
import ZoomOutMapIcon from 'src/shared/icons/ZoomOutMapIcon';
import { styled } from 'styled-components';

const { Dragger } = Upload;

export const StyledImageUpload = styled(Dragger)`
  .ant-upload.ant-upload-drag {
    background-color: ${COLORS.COLOR_SEARCH};
    border: 1px solid ${COLORS.GRAY_LIGHTER};

    .ant-upload-drag-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 1rem;
    }
  }
`;

export const StyledFigure = styled.figure`
  margin: 0;

  position: relative;
  display: inline-block;
  cursor: pointer;

  border-radius: 8px;

  overflow: hidden;

  img {
    width: 100%;
    object-fit: cover;
    max-height: 200px;
  }
`;

export const StyledFigureHoverMask = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
`;

export const StyledZoomIcon = styled(ZoomOutMapIcon)`
  color: white;
  font-size: 2em;
  pointer-events: none;
`;

export const StyledBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledInfo = styled.p`
  margin: 0;
  ${pickThemeFontStyles('12', '26')};
  color: ${COLORS.TEXT_HINT};
`;

export const StyledButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;
