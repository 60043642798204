import { IGetFaqsDTO } from '@/models/faq.model';
import { PaginationFields } from '@/models/pagination.model';

class GetFaqsDTO implements IGetFaqsDTO {
  channelId: number;

  [PaginationFields.PAGE]?: number;

  [PaginationFields.PAGE_SIZE]?: number;

  constructor({ channelId, page = 1 }: { channelId: number; page?: number }) {
    this.channelId = channelId;
    this[PaginationFields.PAGE] = page;
    this[PaginationFields.PAGE_SIZE] = 10;
  }
}

export default GetFaqsDTO;
