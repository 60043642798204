// Globals
import { Suspense, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation } from 'react-router-dom';

// Store
import { useAppState, useAppDispatch } from '@/store';

// Modules
import { getChannelsOptions, selectChannel, useGetChannels, useGetDefaultChannel } from '@/modules/Channels';
import { getSettings, selectSettings, selectSettingsIsLoading } from '@/modules/Settings';
import { logOut } from '@/modules/Auth';
import { getMetatags, GetMetatagsDTO } from '@/modules/Metatags';
import { getRedirects, GetRedirectsDTO } from '@/modules/Redirects';
import { getRobotsTxt } from '@/modules/RobotsTxt';
import { getVariables, GetVariablesDTO } from '@/modules/Variables';
import { getFaqs } from '@/modules/Faq';
import { getCategoriesTree } from '@/modules/Categories';

// Components
import { Image } from '@/components';
import { Button, Select, Spinner } from '@/ui';
import { Menu, Sidebar } from '../../components';
import MainLayoutContent from '../MainLayoutContent/MainLayoutContent';
import { StyledImageHolder, StyledLayout, StyledLogoutButton, StyledTopBlock } from './MainLayoutStyles';

// Helpers
import { getMenuConfig } from '../../helpers/menuConfig';

const MainLayout = () => {
  const { t } = useTranslation(['common']);
  const { activeChannels, isLoading } = useGetChannels();
  const selectedChannel = useAppState(selectChannel);
  const settings = useAppState(selectSettings);
  const isSettingsLoading = useAppState(selectSettingsIsLoading);
  const { onChangeChannel } = useGetDefaultChannel();

  const dispatch = useAppDispatch();
  const path = useLocation();

  const handleChange = async (value: number) => {
    const channel = activeChannels?.find(({ id }) => id === value);
    if (!channel) return;
    onChangeChannel(channel);
    const metaTagsDto = new GetMetatagsDTO({ page: '1', channelId: channel.id });
    const redirectsDto = new GetRedirectsDTO({ page: 1, channelId: channel.id });
    const dto = new GetVariablesDTO({ page: 1, channelId: channel.id });
    const promises: unknown[] = [
      dispatch(getSettings({ channel: channel.slug })),
      dispatch(getMetatags(metaTagsDto)),
      dispatch(getRedirects(redirectsDto)),
      dispatch(getRobotsTxt({ channel: channel.slug })),
      dispatch(getVariables(dto)),
      dispatch(getFaqs({ channelId: channel.id })),
      dispatch(getCategoriesTree({ channelId: channel.id })),
    ];

    await Promise.all(promises);
  };

  const handleLogout = () => {
    dispatch(logOut());
  };

  const options = useMemo(() => getChannelsOptions(activeChannels), [activeChannels]);
  const logo = settings?.images?.find(({ metaType }) => metaType === 'headerLogo')?.file.url;

  return (
    <StyledLayout>
      <Sidebar>
        <StyledTopBlock>
          <Spinner size="small" spinning={isSettingsLoading}>
            <StyledImageHolder>
              <Image src={logo ?? ''} />
            </StyledImageHolder>
          </Spinner>
          {activeChannels && (
            <Select loading={isLoading} options={options} value={selectedChannel?.id} onChange={handleChange} />
          )}
        </StyledTopBlock>
        <Menu items={getMenuConfig(t)} selectedKey={path.pathname} />
        <StyledLogoutButton>
          <Button size="small" type="primary" block onClick={handleLogout}>
            {t('logout')}
          </Button>
        </StyledLogoutButton>
      </Sidebar>
      <MainLayoutContent>
        <Suspense fallback={<Spinner />}>
          <Outlet />
        </Suspense>
      </MainLayoutContent>
    </StyledLayout>
  );
};

export default MainLayout;
