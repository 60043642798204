import pick from 'lodash/pick';
import { paginationFields } from '@/models/pagination.model';
import { showApiErrors } from '@/utils';
import { createSlice, isPending, isRejected } from '@reduxjs/toolkit';
import { createFaq, deleteFaq, getFaqs, updateFaq } from './actionCreators';
import { FaqState, SLICE_NAME, initialState } from './models';

export const faqsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFaqs.fulfilled, (state: FaqState, action) => {
        const { payload } = action;
        state.isLoading = false;
        state.faqs = payload.faqs;
        state.pagination = pick(payload, paginationFields);
      })
      .addCase(updateFaq.fulfilled, (state: FaqState) => {
        state.isLoading = false;
      })
      .addCase(deleteFaq.fulfilled, (state: FaqState, action) => {
        const { meta } = action;
        state.isLoading = false;
        state.faqs = (state.faqs ?? []).filter((faq) => faq.id !== meta.arg.id);
      })
      .addCase(createFaq.fulfilled, (state: FaqState) => {
        state.isLoading = false;
      })
      .addMatcher(isPending(createFaq, deleteFaq, getFaqs, updateFaq), (state: FaqState) => {
        state.isLoading = true;
        state.error = null;
      })
      .addMatcher(isRejected(createFaq, deleteFaq, getFaqs, updateFaq), (state: FaqState, action) => {
        const { error } = action;

        state.isLoading = false;
        state.error = error;
        showApiErrors(error);
      });
  },
});
