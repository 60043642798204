import { Table } from '@/ui';
import styled from 'styled-components';

export const StyledPagesContainerInner = styled.div`
  padding: 32px;
`;

export const PagesStyledTable = styled(Table)`
  tbody .ant-table-cell {
    cursor: pointer;
  }
` as typeof Table;
