// Globals
import { debounce } from 'lodash';
import { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

// Store
import { useAppDispatch } from '@/store';
import { getBlogPosts } from '../../feature';

// Modules

// Helpers
import { GetBlogPostsDTO } from '../../dtos/GetBlogPosts.dto';
import { StyledSearch } from './Search.style';

interface SearchProps {
  loading: boolean;
}

export const Search: FC<SearchProps> = ({ loading }) => {
  const { t } = useTranslation(['common']);
  const dispatch = useAppDispatch();

  const [query, setQuery] = useSearchParams();

  const handleSearch = debounce(async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      setQuery({ search: event.target.value, page: '1' });
    } else {
      setQuery({ page: '1' });
    }
    const dto = new GetBlogPostsDTO({ page: 1, query: event.target.value });
    dispatch(getBlogPosts(dto)).unwrap();
  }, 500);

  return (
    <StyledSearch
      defaultValue={query.get('search') || ''}
      loading={loading}
      size="large"
      onChange={handleSearch}
      placeholder={t('blog_posts_filter_placeholder')}
    />
  );
};
