import { useGetRequiredRule } from '@/hooks';
import { SyncFormFormFields } from '../helpers';
import { useTranslation } from 'react-i18next';

export const useSyncFormRules = () => {
  const { t } = useTranslation();
  const required = useGetRequiredRule({ t });
  return {
    [SyncFormFormFields.CHANNEL]: [required],
    [SyncFormFormFields.TYPE]: [required],
    [SyncFormFormFields.UPDATED_FROM]: [required],
    [SyncFormFormFields.UPDATED_UNTIL]: [required],
  };
};
