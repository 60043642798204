import { showApiErrors } from '@/utils';
import { createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { getEntities, syncByEntity, syncEntity } from './actionCreators';
import { AkeneoSyncState, SLICE_NAME, initialState } from './models';

export const akeneoSyncSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getEntities.fulfilled, (state: AkeneoSyncState, action) => {
        const { payload } = action;
        state.isLoading = false;
        state.entities = Object.values(payload).filter((entity) => entity !== null);
      })
      .addMatcher(isFulfilled(syncEntity, syncByEntity), (state: AkeneoSyncState) => {
        state.isLoading = false;
      })
      .addMatcher(isPending(getEntities, syncEntity, syncByEntity), (state: AkeneoSyncState) => {
        state.isLoading = true;
        state.error = null;
      })
      .addMatcher(isRejected(getEntities, syncEntity, syncByEntity), (state: AkeneoSyncState, action) => {
        const { error } = action;
        state.isLoading = false;
        state.error = error;
        showApiErrors(error);
      });
  },
});
