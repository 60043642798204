import { useGetRequiredRule } from '@/hooks';
import { useTranslation } from 'react-i18next';
import { UpdateCategoryFieldNames } from '../helpers';

export const useUpdateCategoryFormRules = () => {
  const { t } = useTranslation(['validations']);

  const requiredRule = useGetRequiredRule({ t });

  return {
    [UpdateCategoryFieldNames.IMAGE]: [requiredRule],
    [UpdateCategoryFieldNames.ADVANTAGES]: [requiredRule],
    [UpdateCategoryFieldNames.ICON]: [requiredRule],
    [UpdateCategoryFieldNames.VIDEO]: [requiredRule],
    [UpdateCategoryFieldNames.DESCRIPTION]: [requiredRule],
    [UpdateCategoryFieldNames.SEO_BLOCK]: [requiredRule],
  };
};
