import {
  getBlogPostsEndpoints,
  ICreateBlogPostDataDTO,
  ICreateBlogPostResponse,
  IDeleteBlogPostDTO,
  IDeleteBlogPostResponse,
  IGetBlogPostsOptionsDTO,
  IUpdateBlogPostDataDTO,
  IUpdateBlogPostResponse,
  staticBlogPostEndpoints,
  TGetBlogPostsResponse,
} from '@/models/blogPost.model';
import { ApiResponse, convertModelToFormData, httpClient } from '@/utils';

class BlogAPI {
  static getBlogPosts(data: IGetBlogPostsOptionsDTO): ApiResponse<TGetBlogPostsResponse> {
    const { GET_BLOGPOSTS } = staticBlogPostEndpoints;
    return httpClient.get<TGetBlogPostsResponse>(GET_BLOGPOSTS, {
      params: data,
    });
  }

  static createBlogPost(data: ICreateBlogPostDataDTO): ApiResponse<ICreateBlogPostResponse> {
    const { CREATE_BLOGPOST } = staticBlogPostEndpoints;
    return httpClient.post<ICreateBlogPostResponse>(
      CREATE_BLOGPOST,
      convertModelToFormData(data, new FormData(), '', true),
    );
  }

  static updateBlogPost({ slug, ...data }: IUpdateBlogPostDataDTO): ApiResponse<IUpdateBlogPostResponse> {
    const { UPDATE_BLOGPOST } = getBlogPostsEndpoints({ slug });
    return httpClient.patch<IUpdateBlogPostResponse>(
      UPDATE_BLOGPOST,
      convertModelToFormData(data, new FormData(), '', true),
    );
  }

  static deleteBlogPost({ slug }: IDeleteBlogPostDTO): ApiResponse<IDeleteBlogPostResponse> {
    const { DELETE_BLOGPOST } = getBlogPostsEndpoints({ slug });
    return httpClient.delete<IDeleteBlogPostResponse>(DELETE_BLOGPOST);
  }
}

export default BlogAPI;
