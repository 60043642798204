import { httpClient, ApiResponse } from '@/utils';
import {
  staticAkeneoSyncEndpoints,
  IAkeneoSyncEntityDTO,
  IBaseAkeneoSyncResponse,
  IAkeneoSyncProductsDTO,
  getSyncEndpoints,
  IGetEntitiesInfoResponse,
} from '@/models/akeneoSync.model';

class AkeneoSyncAPI {
  static getEntities(): ApiResponse<IGetEntitiesInfoResponse> {
    const { GET_ENTITIES_INFO } = staticAkeneoSyncEndpoints;
    return httpClient.get<IGetEntitiesInfoResponse>(GET_ENTITIES_INFO);
  }

  static syncEntity(data: IAkeneoSyncEntityDTO): ApiResponse<IBaseAkeneoSyncResponse> {
    const { SYNC_ENTITY } = staticAkeneoSyncEndpoints;
    return httpClient.post<IBaseAkeneoSyncResponse>(SYNC_ENTITY, data);
  }

  static syncProducts({ type, ...data }: IAkeneoSyncProductsDTO): ApiResponse<IBaseAkeneoSyncResponse> {
    const { SYNC_BY_ENTITY } = getSyncEndpoints({ type });
    return httpClient.post<IBaseAkeneoSyncResponse>(SYNC_BY_ENTITY, data);
  }
}

export default AkeneoSyncAPI;
