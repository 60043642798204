import { IApiError } from '@/models/apiError.model';
import { IFaq } from '@/models/faq.model';
import { IPagination } from '@/models/pagination.model';

export const SLICE_NAME = 'faq';

export interface FaqState {
  faqs: IFaq[] | null;
  isLoading: boolean;
  error: IApiError | null;
  pagination: IPagination | null;
}

export const initialState: FaqState = {
  faqs: null,
  pagination: null,
  isLoading: false,
  error: null,
};
