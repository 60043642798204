import { PaginationFields } from '@/models/pagination.model';
import { showApiErrors } from '@/utils';
import { createSlice, isPending, isRejected } from '@reduxjs/toolkit';
import { pick } from 'lodash';
import { createBlogPost, getBlogPosts, updateBlogPost, deleteBlogPost } from './actionCreators';
import { initialState, SLICE_NAME, BlogPostsState } from './models';

export const blogPostsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBlogPosts.fulfilled, (state: BlogPostsState, action) => {
        const { payload } = action;
        state.blogPosts = payload?.blogPosts ?? [];
        state.pagination = pick(payload, [PaginationFields.PAGE_SIZE, PaginationFields.TOTAL, PaginationFields.PAGE]);
        state.isLoading = false;
      })
      .addCase(createBlogPost.fulfilled, (state: BlogPostsState, action) => {
        const newBlogPost = action.payload;

        if (state.pagination && state.pagination.page !== 1) {
          state.isLoading = false;
          return;
        }

        state.blogPosts = [newBlogPost, ...(state.blogPosts ?? [])];
        if (state.pagination) {
          state.pagination.total += 1;
          if (state.blogPosts.length > 10) {
            state.blogPosts.pop();
          }
        }

        state.isLoading = false;
      })
      .addCase(updateBlogPost.fulfilled, (state: BlogPostsState, action) => {
        const { payload } = action;
        state.isLoading = false;
        state.blogPosts = (state.blogPosts ?? []).map((blogPost) => {
          if (blogPost.id === payload.id) return payload;
          return blogPost;
        });
      })
      .addCase(deleteBlogPost.fulfilled, (state: BlogPostsState, action) => {
        const { payload } = action;
        state.isLoading = false;
        state.blogPosts = (state.blogPosts ?? []).filter((blogPost) => blogPost.slug !== payload.slug);
        state.pagination!.total -= 1;
      })
      .addMatcher(isPending(getBlogPosts, createBlogPost, updateBlogPost, deleteBlogPost), (state: BlogPostsState) => {
        state.isLoading = true;
        state.error = null;
      })
      .addMatcher(
        isRejected(getBlogPosts, createBlogPost, updateBlogPost, deleteBlogPost),
        (state: BlogPostsState, action) => {
          const { error } = action;
          state.isLoading = false;
          state.error = error;
          showApiErrors(error);
        },
      );
  },
});
