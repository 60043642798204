import { httpClient, ApiResponse } from '@/utils';
import {
  staticFaqEndpoints,
  getFaqEndpoints,
  ICreateFaqDTO,
  ICreateFaqResponse,
  IDeleteFaqDTO,
  IUpdateFaqDTO,
  IGetFaqsDTO,
  IGetFaqsResponse,
} from '@/models/faq.model';

class FaqAPI {
  static getFaqs(params: IGetFaqsDTO): ApiResponse<IGetFaqsResponse> {
    const { GET_FAQS } = staticFaqEndpoints;
    return httpClient.get<IGetFaqsResponse>(GET_FAQS, {
      params,
    });
  }

  static createFaq(data: ICreateFaqDTO): ApiResponse<ICreateFaqResponse> {
    const { CREATE_FAQ } = staticFaqEndpoints;
    return httpClient.post<ICreateFaqResponse>(CREATE_FAQ, data);
  }

  static updateFaq({ id, ...data }: IUpdateFaqDTO): ApiResponse<ICreateFaqResponse> {
    const { UPDATE_FAQ } = getFaqEndpoints({ id });
    return httpClient.patch<ICreateFaqResponse>(UPDATE_FAQ, data);
  }

  static deleteFaq({ id }: IDeleteFaqDTO): ApiResponse<IGetFaqsResponse> {
    const { DELETE_FAQ } = getFaqEndpoints({ id });
    return httpClient.delete<IGetFaqsResponse>(DELETE_FAQ);
  }
}

export default FaqAPI;
