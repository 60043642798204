import { COLORS, pickThemeFontStyles } from '@/theme';
import { Tree } from 'antd';
import styled from 'styled-components';

export const StyledTree = styled(Tree.DirectoryTree)`
  border: 1px solid ${COLORS.GRAY_4};
  border-radius: 0.5rem;

  .ant-tree-treenode {
    padding: 1.25rem 1rem;
    align-items: center !important;
  }

  &.ant-tree-directory .ant-tree-treenode:hover:before {
    bottom: 0;
  }

  .ant-tree-node-content-wrapper-open .ant-tree-title {
    color: ${COLORS.BLACK};
  }

  .leaf {
    background-color: ${COLORS.WHITE} !important;
  }

  .ant-tree-switcher {
    display: flex;
    color: ${COLORS.BLACK};
    align-items: center;

    .ant-tree-switcher-icon {
      font-size: 1.5rem;
    }
  }

  .ant-tree-title {
    color: ${COLORS.BLACK};
    ${pickThemeFontStyles('14', '24', 'BOLD')}
    text-align: start;
    text-transform: capitalize;

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }

  .ant-tree-node-content-wrapper-normal {
    flex: initial;
  }

  .ant-tree-node-content-wrapper {
    padding-left: 0.5rem;

    &:hover {
      background-color: transparent;
    }
  }

  .ant-tree-switcher:not(.ant-tree-switcher-noop):hover:before {
    background-color: transparent;
  }

  .ant-tree-treenode-switcher-open .ant-tree-switcher-icon svg {
    transform: rotate(-180deg);
  }

  .ant-tree-treenode-switcher-close .ant-tree-switcher-icon svg {
    transform: rotate(0);
  }

  .ant-tree-node-content-wrapper-close {
    color: ${COLORS.TEXT_LIGHT};
  }

  .ant-tree-treenode ant-tree-treenode-switcher-close:has(.ant-tree-switcher-noop) {
    background-color: ${COLORS.TEXT_LIGHT};
  }

  .ant-tree-node-content-wrapper-normal .ant-tree-title {
    display: flex !important;
    text-align: left !important;
    min-width: 0;
  }
` as typeof Tree.DirectoryTree;
