import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@/store';
import { CategoriesState, SLICE_NAME } from './models';

const categoriesSelector = (state: RootState): CategoriesState => state[SLICE_NAME];

export const selectCategories = createSelector(categoriesSelector, (state: CategoriesState) => state.categories);

export const selectCategoriesIsLoading = createSelector(
  categoriesSelector,
  (state: CategoriesState) => state.isLoading,
);

export const selectCategoriesError = createSelector(categoriesSelector, (state: CategoriesState) => state.error);
