import { Button } from '@/ui';
import { fileDownload, getImageDimensions } from '@/utils';
import { Image, UploadProps } from 'antd';
import { memo, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  StyledBlock,
  StyledButtons,
  StyledFigure,
  StyledFigureHoverMask,
  StyledImageUpload,
  StyledInfo,
  StyledZoomIcon,
} from './ImageUploadStyles';
import { DownloadOutlined } from '@ant-design/icons';
type IImageUploadProps = UploadProps & {
  value?: string | File;
  onChange?: (file: File | Blob, imageUrl: string) => void;
  accept?: string;
};
const ImageUpload = ({ onChange, value, accept = '.png .svg', ...props }: IImageUploadProps) => {
  const { t } = useTranslation(['common']);
  const [imageDimensions, setImageDimensions] = useState<{ width: number; height: number } | null>(null);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [isHovered, setIsHovered] = useState(false);
  const [initialImage, setInitialImage] = useState<string | null>(null);

  useEffect(() => {
    if (initialImage) {
      getImageDimensions(initialImage).then(setImageDimensions);
    }
  }, [initialImage]);

  useEffect(() => {
    if (!value) return;

    const image = typeof value === 'string' ? value : URL.createObjectURL(value);
    setInitialImage(image);
    return () => {
      setInitialImage(null);
      if (typeof value === 'string') return;
      URL.revokeObjectURL(image);
    };
  }, [value]);

  const handleSelectFile = useCallback(
    async (image: Blob) => {
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onloadend = async () => {
        const url = reader.result as string;
        onChange?.(image, url);
      };
      return false;
    },
    [onChange],
  );
  const handlePreview = async (e: SyntheticEvent) => {
    e.stopPropagation();
    if (!initialImage) return;
    setPreviewImage(initialImage);
    setPreviewOpen(true);
  };

  const handlePrevent = async (e: SyntheticEvent) => {
    e.stopPropagation();
    if (typeof value !== 'string') return;
    try {
      const imageBlob = await fetch(value).then((res) => res.blob());
      const fileName = value.split('/').at(-1) ?? 'image';

      fileDownload(imageBlob, fileName);
    } catch {}
  };

  return (
    <StyledBlock>
      {initialImage && (
        <StyledInfo>
          {t('label_dimensions', {
            width: imageDimensions?.width || 0,
            height: imageDimensions?.height || 0,
          })}
        </StyledInfo>
      )}
      <StyledImageUpload beforeUpload={handleSelectFile} showUploadList={false} accept={accept} {...props}>
        {initialImage && (
          <StyledFigure onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <img
              src={initialImage}
              alt="preview"
              onClick={handlePreview}
              onKeyDown={handlePreview}
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
              role="button"
            />
            {isHovered && (
              <StyledFigureHoverMask>
                <StyledZoomIcon />
              </StyledFigureHoverMask>
            )}
          </StyledFigure>
        )}
        <StyledButtons>
          {typeof value === 'string' && <Button onClick={handlePrevent} icon={<DownloadOutlined />} />}
          <Button>{t('btn_upload_new')}</Button>
        </StyledButtons>
      </StyledImageUpload>
      {previewImage && (
        <Image
          wrapperStyle={{ display: 'none' }}
          preview={{
            visible: previewOpen,
            onVisibleChange: (visible) => setPreviewOpen(visible),
            afterOpenChange: (visible) => !visible && setPreviewImage(''),
          }}
          src={previewImage}
        />
      )}
    </StyledBlock>
  );
};
export default memo(ImageUpload);
