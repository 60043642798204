import { useGetRequiredRule, useLengthValidationRule } from '@/hooks';
import { useTranslation } from 'react-i18next';
import { FaqFieldsNames } from '../helpers/types';

const useFaqFormRules = () => {
  const { t } = useTranslation(['validations']);

  const requiredRule = useGetRequiredRule({ t });
  const lengthRule = useLengthValidationRule({ t, max: 1500 });

  return {
    [FaqFieldsNames.ANSWER]: [requiredRule, lengthRule],
    [FaqFieldsNames.QUESTION]: [requiredRule, lengthRule],
    [FaqFieldsNames.CATEGORY_CODES]: [requiredRule],
    [FaqFieldsNames.IS_ACTIVE]: [requiredRule],
  };
};

export default useFaqFormRules;
