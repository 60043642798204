import { DateFormats } from '@/constants/dates';
import { PenIcon, TrashIcon } from '@/icons';
import { ICategory } from '@/models/category.model';
import { TFunction } from 'i18next';
import { IFaq } from '@/models/faq.model';
import { Button } from '@/ui';
import { renderDate } from '@/utils';
import { TableColumnsType } from 'antd';
import { StyledButtons, StyledStatus, StyledTruncate } from '../components';

export const getTableConfig = (
  t: TFunction,
  onDelete?: (record: IFaq) => void,
  onEdit?: (record: IFaq) => void,
): TableColumnsType<IFaq> => [
  {
    title: t('label_question_many'),
    dataIndex: 'question',
    key: 'question',
    width: 150,
    ellipsis: true,
    render: (question: string) => <StyledTruncate>{question}</StyledTruncate>,
  },
  {
    title: t('label_answer_many'),
    dataIndex: 'answer',
    key: 'answer',
    width: 150,
    ellipsis: true,
    render: (answer: string) => <StyledTruncate>{answer}</StyledTruncate>,
  },

  {
    title: t('label_categories'),
    dataIndex: 'categories',
    key: 'categories',
    width: 500,
    render: (categories: ICategory[]) => categories.flatMap((category) => category?.labels?.en_US).join(', '),
  },

  {
    title: t('label_created_at'),
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (createdAt: string) => renderDate(createdAt, DateFormats.DATE_WITH_TIME),
  },

  {
    title: t('label_status'),
    dataIndex: 'isActive',
    key: 'isActive',
    render: (status: boolean) => (
      <StyledStatus $active={status}>{t(status ? 'label_active' : 'label_inactive')}</StyledStatus>
    ),
  },
  {
    title: t('column_action'),
    key: 'action',
    width: 150,
    render: (_, record) => (
      <StyledButtons>
        <Button
          icon={<PenIcon />}
          type="primary"
          size="small"
          block
          onClick={() => {
            onEdit?.(record);
          }}
        />
        <Button
          type="primary"
          size="small"
          icon={<TrashIcon />}
          danger
          block
          onClick={() => {
            onDelete?.(record);
          }}
        />
      </StyledButtons>
    ),
  },
];
