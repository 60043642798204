import { AkeneoSyncAPI } from '@/api';
import {
  IAkeneoSyncEntityDTO,
  IAkeneoSyncProductsDTO,
  IBaseAkeneoSyncResponse,
  IGetEntitiesInfoResponse,
} from '@/models/akeneoSync.model';
import { IApiError } from '@/models/apiError.model';
import { formatApiError } from '@/utils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { SLICE_NAME } from './models';

export const getEntities = createAsyncThunk<IGetEntitiesInfoResponse, void, { serializedErrorType: IApiError }>(
  `${SLICE_NAME}/getEntities`,
  async () => {
    const response = await AkeneoSyncAPI.getEntities();
    return response.data;
  },
  { serializeError: formatApiError },
);

export const syncEntity = createAsyncThunk<
  IBaseAkeneoSyncResponse,
  IAkeneoSyncEntityDTO,
  { serializedErrorType: IApiError }
>(
  `${SLICE_NAME}/syncEntity`,
  async (data) => {
    const response = await AkeneoSyncAPI.syncEntity(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const syncByEntity = createAsyncThunk<
  IBaseAkeneoSyncResponse,
  IAkeneoSyncProductsDTO,
  { serializedErrorType: IApiError }
>(
  `${SLICE_NAME}/syncProducts`,
  async (data) => {
    const response = await AkeneoSyncAPI.syncProducts(data);
    return response.data;
  },
  { serializeError: formatApiError },
);
