export enum SyncFormFormFields {
  UPDATED_FROM = 'updatedFrom',
  UPDATED_UNTIL = 'updatedUntil',
  CHANNEL = 'channelSlugs',
  TYPE = 'type',
}

export type TSyncFormValues = {
  [SyncFormFormFields.TYPE]: SyncType;
  [SyncFormFormFields.UPDATED_FROM]: string;
  [SyncFormFormFields.UPDATED_UNTIL]: string;
  [SyncFormFormFields.CHANNEL]: string[];
};

export enum SyncType {
  PRODUCTS = 'products',
  PRODUCT_MODELS = 'product-models',
}
