export enum PaginationFields {
  PAGE_SIZE = 'pageSize',
  PAGE = 'page',
  TOTAL = 'total',
}

export type TPaginationFields = keyof IPagination;
export const paginationFields = ['pageSize', 'page', 'total'] as TPaginationFields[];

export interface IPagination {
  [PaginationFields.PAGE_SIZE]: number;
  [PaginationFields.PAGE]: number;
  [PaginationFields.TOTAL]: number;
}

export interface IPaginationDTO {
  [PaginationFields.PAGE_SIZE]?: string | number;
  [PaginationFields.PAGE]?: string | number;
}

export interface IPaginationResponse {
  [PaginationFields.PAGE_SIZE]: number;
  [PaginationFields.PAGE]: number;
  [PaginationFields.TOTAL]: number;
}
