import { COLORS, pickThemeFontStyles } from '@/theme';
import { Table } from 'antd';
import { styled } from 'styled-components';

export const StyledTable = styled(Table)`
  margin-bottom: 1.5rem;

  .ant-table-thead {
    border-width: 0;
    border-collapse: collapse;
  }
  .ant-table-thead .ant-table-cell {
    background-color: ${({ theme }) => theme.token.colorPrimary};
    color: ${COLORS.WHITE};
    ${pickThemeFontStyles('16', '24', 'SEMI_BOLD')};
    padding: 0.625rem 0.5rem;
  }

  .ant-table-thead .sub-header .ant-table-cell {
    background-color: ${COLORS.GRAY};
  }

  .ant-table-thead .sub-header .ant-table-cell.select {
    padding: 0.5rem 1rem;
  }

  .ant-table-cell:before,
  .ant-table-thead .ant-table-cell:before {
    background-color: ${COLORS.GRAY_LIGHTER} !important;
    position: absolute;
    top: 0 !important;
    inset-inline-end: 0;
    width: 1px;
    height: 100% !important;
    transition: background-color 0.2s;
    transform: none !important;
    content: '';
  }

  & .ant-table:not(.ant-table-empty) tbody > tr:nth-child(odd) > .ant-table-cell {
    background-color: ${COLORS.COLOR_SEARCH};
  }

  tbody .ant-table-cell a {
    text-decoration: underline;
  }

  .ant-table-title + .ant-table-container {
    .ant-table-thead .ant-table-cell {
      &:first-of-type {
        border-top-left-radius: 0.375rem !important;
      }

      &:last-of-type {
        border-top-right-radius: 0.375rem !important;
      }
    }
  }
` as typeof Table;
