import { IPaginationDTO, IPaginationResponse } from '@/models/pagination.model';

export enum BlogPostsEndpoint {
  ROOT = '/blog-posts',
}

export const staticBlogPostEndpoints = {
  GET_BLOGPOSTS: BlogPostsEndpoint.ROOT,
  CREATE_BLOGPOST: BlogPostsEndpoint.ROOT,
};

export const getBlogPostsEndpoints = ({ slug }: { slug: string }) => ({
  UPDATE_BLOGPOST: `${BlogPostsEndpoint.ROOT}/${slug}`,
  DELETE_BLOGPOST: `${BlogPostsEndpoint.ROOT}/${slug}`,
});

// ============== Model ==============

export enum BlogPostStatus {
  DRAFT = 'draft',
  PUBLISHED = 'published',
  SCHEDULED = 'scheduled',
}

export interface IImage {
  id: string;
  file: {
    url: string;
    id: string;
  };
  metaType: 'banner';
}

export interface IBlogPostChannel {
  id: string;
  channelId: number;
  blogPostId: string;
}

export interface IBlogPost {
  id: string;
  slug: string;
  title: string;
  content: string;
  shortDescription: string;
  publishedAt: Date;
  status: BlogPostStatus;
  blogPostChannels: IBlogPostChannel[];
  blogPostFiles: IImage[];
  createdAt: Date;
  updatedAt: Date;
}

// ============== DTO ==============

export type IDeleteBlogPostDTO = {
  slug: string;
};

export type IGetBlogPostsOptionsDTO = IPaginationDTO & {
  channelSlug?: string;
  pageSize: number;
  page: number;
  withDrafts?: boolean;
  injectVariables?: boolean;
  search?: string;
};

export interface ICreateBlogPostDataDTO {
  slug: string;
  title: string;
  shortDescription: string;
  content: string;
  status?: BlogPostStatus;
  publishedAt?: string;
  blogPostChannels?: number[];
  blogPostFiles?: string[];
}

export type IUpdateBlogPostDataDTO = ICreateBlogPostDataDTO;

// ============== Response ==============

export type ICreateBlogPostResponse = IBlogPost;

export type IUpdateBlogPostResponse = IBlogPost;

export type IDeleteBlogPostResponse = IBlogPost;

export type TGetBlogPostsResponse = IPaginationResponse & {
  blogPosts: IBlogPost[];
};
