import { FormItem } from '@/components';
import styled from 'styled-components';

export const StyledFormItem = styled(FormItem)`
  .ant-form-item-row {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .ant-form-item-control {
    flex-grow: 0;
  }
`;
