import { CheckIcon, PenIcon } from '@/icons';
import { ReviewStatus } from '@/models/review.model';
import { useAppDispatch } from '@/store';
import { COLORS } from '@/theme';
import { Button, PopConfirm } from '@/ui';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import TrashIcon from 'src/shared/icons/TrashIcon';
import { approveReview, deleteReview } from '../../feature';

interface ReviewDetailsModalFooterProps {
  reviewStatus?: ReviewStatus;
  reviewId?: string;
  closeModal: () => void;
  toggleEditMode: () => void;
  reviewInEditMode: boolean;
  submitForm: () => void;
}

export const ReviewDetailsModalFooter: FC<ReviewDetailsModalFooterProps> = ({
  reviewId,
  reviewStatus,
  closeModal,
  toggleEditMode,
  reviewInEditMode,
  submitForm,
}) => {
  const { t } = useTranslation(['common']);
  const dispatch = useAppDispatch();

  if (!reviewId) {
    return null;
  }

  if (reviewInEditMode) {
    return (
      <Button icon={<CheckIcon />} type="primary" onClick={submitForm}>
        {t('save_changes')}
      </Button>
    );
  }

  return (
    <>
      {reviewStatus !== ReviewStatus.APPROVED && (
        <PopConfirm
          title={t('approve_review_confirm')}
          description={t('approve_review_description')}
          onConfirm={() => {
            dispatch(approveReview(reviewId));
            closeModal();
          }}
        >
          <Button
            icon={<CheckIcon />}
            type="primary"
            style={{
              backgroundColor: COLORS.GREEN,
            }}
          >
            {t('approve')}
          </Button>
        </PopConfirm>
      )}
      <Button icon={<PenIcon />} title={t('edit_review')} type="primary" onClick={toggleEditMode} />
      <PopConfirm
        title={t('delete_review_confirm')}
        description={t('delete_review_description')}
        onConfirm={() => {
          dispatch(deleteReview(reviewId));
          closeModal();
        }}
      >
        <Button icon={<TrashIcon />} title={t('delete_review')} type="primary" danger />
      </PopConfirm>
    </>
  );
};
