// Globals
import { useTranslation } from 'react-i18next';

// Components
import { Form, FormItem } from '@/components';
import { DatePicker, Input, Select, TextEditor, useVariableDetector, VariableDetector, VariableType } from '@/ui';

// Helpers

import { CheckIcon } from '@/icons';
import { BlogPostStatus } from '@/models/blogPost.model';
import { ChannelsSelect } from '@/modules/Pages/components/ChannelsSelect';
import { WideFormItem } from '@/modules/Reviews/components/CreateReviewForm';
import { ImageUpload } from '@/modules/Settings';
import { OpenVariablesModuleButton } from '@/modules/Variables/components/OpenVariablesModuleButton';
import {
  BlogPostFormFields,
  BlogPostFormType,
  TCreateBlogPostFormValues,
  TUpdateBlogPostFormValues,
} from '../../helpers/types';
import useBlogPostFormRules from '../../hooks/useBlogPostFormRules';
import { StyledButton, StyledButtons } from './BlogPostForm.styles';

type TBlogPostFormProps = {
  onSubmit: (values: TCreateBlogPostFormValues) => void;
  initialValues?: Partial<TUpdateBlogPostFormValues>;
  formType: BlogPostFormType;
  isLoading?: boolean;
};

const BlogPostForm = ({ onSubmit, initialValues, formType, isLoading }: TBlogPostFormProps) => {
  const rules = useBlogPostFormRules();
  const { t } = useTranslation(['common']);
  const { variables, selectVariableInInput, registerInputRef, handleInputChange } = useVariableDetector();

  const statusOptions = [
    { value: BlogPostStatus.DRAFT, label: String(BlogPostStatus.DRAFT) },
    { value: BlogPostStatus.SCHEDULED, label: String(BlogPostStatus.SCHEDULED) },
    { value: BlogPostStatus.PUBLISHED, label: String(BlogPostStatus.PUBLISHED) },
  ];

  return (
    <>
      <VariableDetector variables={variables} onSelectVariable={selectVariableInInput} />
      <Form onFinish={onSubmit} initialValues={initialValues}>
        <FormItem
          name={BlogPostFormFields.BLOG_POST_CHANNELS}
          label={t('label_channel')}
          rules={rules[BlogPostFormFields.BLOG_POST_CHANNELS]}
        >
          <ChannelsSelect filterCurrentChannel={false} placeholder={t('placeholder_channels')} />
        </FormItem>

        <FormItem name={BlogPostFormFields.SLUG} label={t('label_slug')} rules={rules[BlogPostFormFields.SLUG]}>
          <Input disabled={formType === BlogPostFormType.UPDATE} placeholder={t('placeholder_blog_post_slug')} />
        </FormItem>

        <FormItem name={BlogPostFormFields.STATUS} label={t('label_status')} rules={rules[BlogPostFormFields.STATUS]}>
          <Select options={statusOptions} placeholder={t('placeholder_status')} />
        </FormItem>

        <FormItem
          name={BlogPostFormFields.PUBLISHED_AT}
          label={t('label_published_at')}
          rules={rules[BlogPostFormFields.PUBLISHED_AT]}
        >
          <DatePicker showTime placeholder={t('placeholder_published_at')} />
        </FormItem>

        <FormItem name={BlogPostFormFields.TITLE} label={t('label_title')} rules={rules[BlogPostFormFields.TITLE]}>
          <Input
            ref={(ref) => registerInputRef(t('label_title'), ref, VariableType.INPUT)}
            onChange={(e) => handleInputChange(t('label_title'), e.target.value)}
            placeholder={t('placeholder_blog_post_title')}
          />
        </FormItem>

        <FormItem>
          <WideFormItem label={t('label_banner')} name={BlogPostFormFields.BANNER}>
            <ImageUpload />
          </WideFormItem>
        </FormItem>

        <FormItem
          name={BlogPostFormFields.SHORT_DESCRIPTION}
          label={t('label_short_description')}
          rules={rules[BlogPostFormFields.SHORT_DESCRIPTION]}
        >
          <Input
            ref={(ref) => registerInputRef(t('label_short_description'), ref, VariableType.INPUT)}
            onChange={(e) => handleInputChange(t('label_short_description'), e.target.value)}
            placeholder={t('placeholder_short_description')}
          />
        </FormItem>

        <FormItem
          name={BlogPostFormFields.CONTENT}
          label={t('label_content')}
          rules={rules[BlogPostFormFields.CONTENT]}
        >
          <TextEditor
            ref={(ref) => registerInputRef(t('label_content'), ref, VariableType.TEXT_EDITOR)}
            onChange={(_, plainText) => handleInputChange(t('label_content'), plainText)}
            placeholder={t('placeholder_content')}
          />
        </FormItem>

        <StyledButtons>
          <OpenVariablesModuleButton />
          <StyledButton
            type="primary"
            htmlType="submit"
            icon={formType === BlogPostFormType.UPDATE && <CheckIcon />}
            loading={isLoading}
          >
            {t(formType === BlogPostFormType.CREATE ? 'btn_create_blog_post' : 'btn_save')}
          </StyledButton>
        </StyledButtons>
      </Form>
    </>
  );
};

export default BlogPostForm;
