// Globals
import { useTranslation } from 'react-i18next';

// Modules
import { selectChannels } from '@/modules/Channels';
import { Header } from '@/modules/Layout';

// Store
import { useAppDispatch, useAppState } from '@/store';
import { getEntities, syncByEntity, syncEntity } from '../../feature';

// Components
import { FormItem, showSuccessToast, useForm } from '@/components';
import { Button, Select, Spinner } from '@/ui';
import {
  StyledContainer,
  StyledDatePicker,
  StyledForm,
  StyledSyncForm,
  StyledTable,
  StyledTitle,
} from './AkeneoSyncContainerStyles';

// Models
import { EntityTypes } from '@/models/akeneoSync.model';

// Helpers
import {
  getChannelsOptions,
  getEntitiesOptions,
  getSyncOptions,
  getSyncTableConfig,
  SyncFormFormFields,
  TSyncFormValues,
} from '../../helpers';
import { useGetEntities, useSyncFormRules } from '../../hooks';

const AkeneoSyncContainer = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const channels = useAppState(selectChannels);
  const { entities, isLoading } = useGetEntities();
  const syncTypeOptions = getSyncOptions(t);
  const entitiesOptions = getEntitiesOptions(t);
  const channelsOptions = getChannelsOptions(channels);
  const rules = useSyncFormRules();
  const tableConfig = getSyncTableConfig(t);
  const [productSyncForm] = useForm();
  const [entitySyncForm] = useForm();

  const handleSubmit = async (values: TSyncFormValues) => {
    try {
      await dispatch(syncByEntity(values)).unwrap();
      productSyncForm.resetFields();
      showSuccessToast({
        message: t('message_sync_success'),
      });
    } catch {}
  };

  const handleSubmitSyncEntity = async (values: TSyncFormValues) => {
    try {
      await dispatch(
        syncEntity({
          entityTypes: values[SyncFormFormFields.TYPE] as unknown as EntityTypes[],
        }),
      ).unwrap();
      dispatch(getEntities());
      entitySyncForm.resetFields();
      showSuccessToast({
        message: t('message_sync_success'),
      });
    } catch {}
  };

  return (
    <>
      <Header title={t('menu_akeneo')} />
      <Spinner spinning={isLoading}>
        <StyledContainer>
          <div>
            <StyledTitle>{t('title_products_sync')}</StyledTitle>
            <StyledForm onFinish={handleSubmit} form={productSyncForm}>
              <FormItem
                name={SyncFormFormFields.TYPE}
                label={t('label_what_to_sync')}
                rules={rules[SyncFormFormFields.TYPE]}
              >
                <Select options={syncTypeOptions} allowClear />
              </FormItem>
              <FormItem
                name={SyncFormFormFields.CHANNEL}
                label={t('label_channels')}
                rules={rules[SyncFormFormFields.CHANNEL]}
              >
                <Select isMultiselect options={channelsOptions} allowClear />
              </FormItem>
              <FormItem
                name={SyncFormFormFields.UPDATED_FROM}
                label={t('label_date_from')}
                rules={rules[SyncFormFormFields.UPDATED_FROM]}
              >
                <StyledDatePicker allowClear />
              </FormItem>
              <FormItem
                name={SyncFormFormFields.UPDATED_UNTIL}
                label={t('label_date_to')}
                rules={rules[SyncFormFormFields.UPDATED_UNTIL]}
              >
                <StyledDatePicker allowClear />
              </FormItem>
              <Button block type="primary" htmlType="submit">
                {t('button_sync_products')}
              </Button>
            </StyledForm>
          </div>
          <div>
            <div>
              <StyledTitle>{t('label_products_sync')}</StyledTitle>
              <StyledSyncForm onFinish={handleSubmitSyncEntity} layout="inline" form={entitySyncForm}>
                <FormItem name={SyncFormFormFields.TYPE} rules={rules[SyncFormFormFields.TYPE]}>
                  <Select isMultiselect options={entitiesOptions} />
                </FormItem>
                <Button block type="primary" htmlType="submit">
                  {t('button_sync_products')}
                </Button>
              </StyledSyncForm>
            </div>
            <StyledTable columns={tableConfig} dataSource={entities ?? []} rowKey="id" pagination={false} />
          </div>
        </StyledContainer>
      </Spinner>
    </>
  );
};

export default AkeneoSyncContainer;
