import { RootState } from '@/store';
import { createSelector } from '@reduxjs/toolkit';
import { BlogPostsState, SLICE_NAME } from './models';

const blogPostsSelector = (state: RootState): BlogPostsState => state[SLICE_NAME];

export const selectBlogPosts = createSelector(blogPostsSelector, (state: BlogPostsState) => state.blogPosts);

export const selectBlogPostsIsLoading = createSelector(blogPostsSelector, (state: BlogPostsState) => state.isLoading);

export const selectBlogPostsError = createSelector(blogPostsSelector, (state: BlogPostsState) => state.error);

export const selectBlogPostsPagination = createSelector(blogPostsSelector, (state: BlogPostsState) => state.pagination);

export const selectBlogPostsSearch = createSelector(blogPostsSelector, (state: BlogPostsState) => state.search);
