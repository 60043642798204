import { IApiError } from '@/models/apiError.model';
import { ICategory } from '@/models/category.model';

export const SLICE_NAME = 'categories';

export interface CategoriesState {
  categories: ICategory | null;
  isLoading: boolean;
  error: IApiError | null;
}

export const initialState: CategoriesState = {
  categories: null,
  isLoading: false,
  error: null,
};
