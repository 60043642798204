import { Modal, ModalSize } from '@/components';
import { PlusIcon, TrashIcon } from '@/icons';
import { IBlogPost } from '@/models/blogPost.model';
import { Header } from '@/modules/Layout';
import { useAppDispatch } from '@/store';
import { Button, Table } from '@/ui';
import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Search } from '../../components/Search';
import { GetBlogPostsDTO } from '../../dtos/GetBlogPosts.dto';
import { createBlogPost, deleteBlogPost, getBlogPosts, updateBlogPost } from '../../feature';
import { blogPostsColumns } from '../../helpers/blogPostColumns';

import dayjs from 'dayjs';
import { StyledPagination } from 'src/shared/ui/Pagination/PaginationStyles';
import BlogPostForm from '../../components/BlogPostForm/BlogPostForm';
import CreateBlogPostDTO from '../../dtos/CreateBlogPosts.dto';
import UpdateBlogPostDTO from '../../dtos/UpdateBlogPosts.dto';
import {
  BlogPostFormFields,
  BlogPostFormType,
  TCreateBlogPostFormValues,
  TUpdateBlogPostFormValues,
} from '../../helpers/types';
import { useBlogPosts } from '../../hooks/useBlogPosts';
import { StyledButtons, StyledContainer, StyledMessage } from './BlogContainer.styles';

export const BlogContainer: FC = () => {
  const { t } = useTranslation(['common']);
  const { blogPosts, isLoading, currentChannel, pagination } = useBlogPosts();
  const [query] = useSearchParams();
  const dispatch = useAppDispatch();

  const [selectedBlogPost, setSelectedBlogPost] = useState<IBlogPost | null>(null);
  const [formType, setFormType] = useState<BlogPostFormType | null>(null);

  const handleDelete = (record: IBlogPost) => {
    setSelectedBlogPost(record);
    setFormType(BlogPostFormType.DELETE);
  };

  const handleOpenEdit = (record: IBlogPost) => {
    setSelectedBlogPost(record);
    setFormType(BlogPostFormType.UPDATE);
  };

  const handlePageChange = (page: number) => {
    if (!currentChannel?.id) return;
    const dto = new GetBlogPostsDTO({ page, query: query.get('search') || undefined });
    dispatch(getBlogPosts(dto)).unwrap();
  };

  const handleOpenCreate = () => {
    setSelectedBlogPost(null);
    setFormType(BlogPostFormType.CREATE);
  };

  const handleCloseModal = () => {
    setFormType(null);
  };

  const handleSubmitUpdateBlogPost = useCallback(
    async (values: TUpdateBlogPostFormValues) => {
      console.log(values);
      if (!selectedBlogPost?.id) return;
      const updateDto = new UpdateBlogPostDTO({
        ...values,
        slug: selectedBlogPost?.slug,
        blogPostFiles: [],
      });
      console.log(updateDto);
      try {
        await dispatch(updateBlogPost(updateDto)).unwrap();
        setSelectedBlogPost(null);
        setFormType(null);
      } catch {}
    },
    [dispatch, selectedBlogPost?.id, selectedBlogPost?.slug],
  );

  const handleConfirmDelete = useCallback(async () => {
    if (!selectedBlogPost) return;
    try {
      await dispatch(deleteBlogPost({ slug: selectedBlogPost.slug })).unwrap();
    } catch (error) {}

    setSelectedBlogPost(null);
    setFormType(null);
  }, [dispatch, selectedBlogPost]);

  const handleSubmitCreateBlogPost = useCallback(
    async (values: TCreateBlogPostFormValues) => {
      setSelectedBlogPost(null);
      if (!currentChannel?.id) return;
      const createDto = new CreateBlogPostDTO({ ...values, blogPostFiles: [] });
      const getVariablesDto = new GetBlogPostsDTO({
        page: query.get('page') || 1,
        query: query.get('search') || undefined,
      });
      try {
        await dispatch(createBlogPost(createDto)).unwrap();
        if (query.get('page') !== '1') {
          dispatch(getBlogPosts(getVariablesDto)).unwrap();
        }
        setFormType(null);
      } catch {}
    },
    [currentChannel?.id, dispatch, query],
  );

  const getModalContent = useCallback(
    (type: BlogPostFormType) => {
      switch (type) {
        case BlogPostFormType.UPDATE:
          return (
            <BlogPostForm
              onSubmit={handleSubmitUpdateBlogPost}
              isLoading={isLoading}
              formType={BlogPostFormType.UPDATE}
              initialValues={{
                [BlogPostFormFields.SLUG]: selectedBlogPost?.slug,
                [BlogPostFormFields.CONTENT]: selectedBlogPost?.content,
                [BlogPostFormFields.TITLE]: selectedBlogPost?.title,
                [BlogPostFormFields.SHORT_DESCRIPTION]: selectedBlogPost?.shortDescription,
                [BlogPostFormFields.STATUS]: selectedBlogPost?.status,
                [BlogPostFormFields.PUBLISHED_AT]: dayjs(selectedBlogPost?.publishedAt),
                [BlogPostFormFields.BLOG_POST_CHANNELS]: selectedBlogPost?.blogPostChannels?.map(
                  (blogPostChannel) => blogPostChannel.channelId,
                ),
                [BlogPostFormFields.BANNER]: selectedBlogPost?.blogPostFiles?.find((file) => file.metaType === 'banner')
                  ?.file?.url,
              }}
            />
          );
        case BlogPostFormType.DELETE:
          return (
            <div>
              <StyledMessage>{t(`delete_blog_post_message`)}</StyledMessage>
              <StyledButtons>
                <Button
                  type="primary"
                  danger
                  size="small"
                  onClick={handleConfirmDelete}
                  icon={<TrashIcon />}
                  loading={isLoading}
                >
                  {t('delete_blog_post')}
                </Button>
              </StyledButtons>
            </div>
          );
        case BlogPostFormType.CREATE:
          return (
            <BlogPostForm
              formType={BlogPostFormType.CREATE}
              isLoading={isLoading}
              onSubmit={handleSubmitCreateBlogPost}
            />
          );
        default:
          return null;
      }
    },
    [handleConfirmDelete, handleSubmitCreateBlogPost, handleSubmitUpdateBlogPost, isLoading, selectedBlogPost, t],
  );

  const title = useMemo(() => {
    switch (formType) {
      case BlogPostFormType.UPDATE:
        return t('title_edit_blog_post');
      case BlogPostFormType.DELETE:
        return t('delete_blog_post_confirm', { code: selectedBlogPost?.slug });
      case BlogPostFormType.CREATE:
        return t('title_create_blog_post');
      default:
        return null;
    }
  }, [formType, selectedBlogPost?.slug, t]);

  return (
    <>
      <Header title={t('menu_blog')}>
        <Button icon={<PlusIcon />} type="primary" onClick={handleOpenCreate}>
          {t('btn_add_blog_post')}
        </Button>
      </Header>
      <StyledContainer>
        <Search loading={isLoading} />
        <Table
          columns={blogPostsColumns(t, handleDelete, handleOpenEdit)}
          rowKey="id"
          dataSource={blogPosts ?? []}
          loading={isLoading}
          pagination={false}
          size="small"
          rowHoverable
          virtual
        />
        <StyledPagination
          disabled={isLoading}
          total={pagination?.total ?? 0}
          current={pagination?.page ?? 1}
          pageSize={pagination?.pageSize ?? 10}
          onChange={handlePageChange}
          hideOnSinglePage
        />
      </StyledContainer>
      <Modal
        title={title}
        size={formType === BlogPostFormType.DELETE ? ModalSize.MEDIUM : ModalSize.LARGE}
        visible={!!formType}
        onCancel={handleCloseModal}
        destroyOnClose
      >
        {!!formType && getModalContent(formType)}
      </Modal>
    </>
  );
};
