import { showApiErrors } from '@/utils';
import { createSlice, isPending, isRejected } from '@reduxjs/toolkit';
import { getCategories, getCategoriesTree, updateCategory } from './actionCreators';
import { CategoriesState, SLICE_NAME, initialState } from './models';

export const categorySlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCategories.fulfilled, (state: CategoriesState, action) => {
        const { payload } = action;
        state.isLoading = false;
        state.categories = payload;
      })
      .addCase(getCategoriesTree.fulfilled, (state: CategoriesState, action) => {
        const { payload } = action;
        state.isLoading = false;
        state.categories = payload;
      })
      .addCase(updateCategory.fulfilled, (state: CategoriesState) => {
        state.isLoading = false;
      })
      .addMatcher(isPending(getCategories, getCategoriesTree, updateCategory), (state: CategoriesState) => {
        state.isLoading = true;
        state.error = null;
      })
      .addMatcher(isRejected(getCategories, getCategoriesTree, updateCategory), (state: CategoriesState, action) => {
        const { error } = action;

        state.isLoading = false;
        state.error = error;
        showApiErrors(error);
      });
  },
});
