import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 2rem;
`;

export const StyledButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
`;

export const StyledMessage = styled.p``;

export const StyledSearchWrapper = styled.div`
  max-width: 40%;
  margin-bottom: 1rem;
`;
