import { IEntityInfo } from '@/models/akeneoSync.model';
import { IApiError } from '@/models/apiError.model';

export const SLICE_NAME = 'akeneoSync';

export interface AkeneoSyncState {
  entities: IEntityInfo[] | null;
  isLoading: boolean;
  error: IApiError | null;
}

export const initialState: AkeneoSyncState = {
  entities: null,
  isLoading: false,
  error: null,
};
