import { IAdvantage, IUpdateCategoryDTO } from '@/models/category.model';
import { checkIfBinary } from '@/utils';

class UpdateCategoryDTO implements IUpdateCategoryDTO {
  id: string;

  advantages?: IAdvantage[];

  image?: Blob | string;

  icon?: Blob | string;

  video?: Blob | string;

  description?: string;

  seoBlocks?: string[];

  constructor(data: IUpdateCategoryDTO) {
    this.id = data.id;
    this.description = data.description;

    if (data.advantages) {
      this.advantages = data.advantages;
    }

    if (data.seoBlocks) {
      this.seoBlocks = data.seoBlocks;
    }

    if (checkIfBinary(data.image)) {
      this.image = data.image;
    }

    if (checkIfBinary(data.icon)) {
      this.icon = data.icon;
    }

    if (checkIfBinary(data.video)) {
      this.video = data.video;
    }
  }
}

export default UpdateCategoryDTO;
