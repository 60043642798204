import { COLORS } from '@/theme';
import { styled } from 'styled-components';

export const StyledButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
`;

export const StyledStatus = styled.div<{
  $active?: boolean;
}>`
  display: inline-flex;
  border-radius: 99em;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 0.5rem;
  color: ${COLORS.WHITE};
  background-color: ${({ $active }) => ($active ? COLORS.GREEN : COLORS.RED)};
`;

export const StyledTruncate = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 15rem;
`;
