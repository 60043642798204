import { ICategory } from '@/models/category.model';
import { IPaginationDTO, IPaginationResponse } from '@/models/pagination.model';

export enum FaqEndpoints {
  ROOT = '/faq',
}

export const staticFaqEndpoints = {
  GET_FAQS: FaqEndpoints.ROOT,
  CREATE_FAQ: FaqEndpoints.ROOT,
};

export const getFaqEndpoints = ({ id }: { id: string }) => ({
  UPDATE_FAQ: `${FaqEndpoints.ROOT}/${id}`,
  DELETE_FAQ: `${FaqEndpoints.ROOT}/${id}`,
});

export interface IFaq {
  id: string;
  channelId: number;
  question: string;
  answer: string;
  createdAt: string;
  updatedAt: string;
  categories: ICategory[];
  isActive: boolean;
}

// ============== DTO ==============

export interface IGetFaqsDTO extends IPaginationDTO {
  channelId: number;
}

export interface IBaseUpdateFaqDTO {
  question: string;
  answer: string;
  categoryCodes: string[];
  isActive: boolean;
}

export interface ICreateFaqDTO extends IBaseUpdateFaqDTO {
  channelId: number;
}

export interface IUpdateFaqDTO extends IBaseUpdateFaqDTO {
  id: string;
}

export interface IDeleteFaqDTO {
  id: string;
}

// ============== Response ==============

export interface IGetFaqsResponse extends IPaginationResponse {
  faqs: IFaq[];
}

export interface ICreateFaqResponse {
  id: string;
  channelId: number;
  question: string;
  answer: string;
  createdAt: string;
  updatedAt: string;
}
