import { COLORS } from '@/theme';
import styled, { css } from 'styled-components';

export const imageStyles = css`
  border-radius: 0.5rem;
  max-width: 5rem;
  width: 5rem;
  height: 2.5rem;
  object-fit: cover;
`;

export const StyledImagePlaceholder = styled.div`
  background: ${COLORS.GRAY_LIGHTER};
  ${imageStyles};
`;

export const StyledBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .anticon {
    color: ${COLORS.BLUE_DARK};
  }
`;

export const StyledImage = styled.img`
  ${imageStyles};
`;
