export enum FaqFieldsNames {
  QUESTION = 'question',
  ANSWER = 'answer',
  CATEGORY_CODES = 'categoryCodes',
  IS_ACTIVE = 'isActive',
}

export type TFaqFormValues = {
  [FaqFieldsNames.QUESTION]: string;
  [FaqFieldsNames.ANSWER]: string;
  [FaqFieldsNames.CATEGORY_CODES]: string[];
  [FaqFieldsNames.IS_ACTIVE]: boolean;
};
