import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@/store';
import { FaqState, SLICE_NAME } from './models';

const faqsSelector = (state: RootState): FaqState => state[SLICE_NAME];

export const selectFaqs = createSelector(faqsSelector, (state: FaqState) => state.faqs);

export const selectFaqsIsLoading = createSelector(faqsSelector, (state: FaqState) => state.isLoading);

export const selectFaqsError = createSelector(faqsSelector, (state: FaqState) => state.error);

export const selectPagination = createSelector(faqsSelector, (state: FaqState) => state.pagination);
