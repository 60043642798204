// Globals
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Modules
import { Header } from '@/modules/Layout';

// Models
import { ICategory } from '@/models/category.model';

// Components
import { Tree } from '@/components';
import { Spinner } from '@/ui';
import UpdateCategoryContainer from '../UpdateCategoryContainer/UpdateCategoryContainer';
import { StyledCategoriesContainer } from './CategoriesContainerStyles';

// Helpers
import { mapCategoriesToTree } from '../../helpers';
import { useGetCategories } from '../../hooks';

const CategoriesContainer = () => {
  const { t } = useTranslation();
  const [selectedCategory, setSelectedCategory] = useState<ICategory | null>(null);

  const { categories, isLoading } = useGetCategories();

  const handleCategorySelect = useCallback((category: ICategory) => setSelectedCategory(category), []);

  const treeData = useMemo(
    () =>
      mapCategoriesToTree({
        categories: categories?.children ?? [],
        onClick: handleCategorySelect,
      }),
    [categories?.children, handleCategorySelect],
  );

  return (
    <Spinner spinning={isLoading}>
      <Header title={t('menu_categories')} />
      <StyledCategoriesContainer>
        {categories && <Tree treeData={treeData} blockNode icon={false} selectable={false} multiple checkStrictly />}
        {selectedCategory && <UpdateCategoryContainer category={selectedCategory} setCategory={setSelectedCategory} />}
      </StyledCategoriesContainer>
    </Spinner>
  );
};

export default CategoriesContainer;
