import { createAsyncThunk } from '@reduxjs/toolkit';
import { FaqAPI } from '@/api';
import { formatApiError } from '@/utils';
import { IApiError } from '@/models/apiError.model';
import {
  IGetFaqsDTO,
  IGetFaqsResponse,
  ICreateFaqDTO,
  ICreateFaqResponse,
  IUpdateFaqDTO,
  IDeleteFaqDTO,
} from '@/models/faq.model';
import { SLICE_NAME } from './models';

export const getFaqs = createAsyncThunk<IGetFaqsResponse, IGetFaqsDTO, { serializedErrorType: IApiError }>(
  `${SLICE_NAME}/getFaqs`,
  async (params) => {
    const response = await FaqAPI.getFaqs(params);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const updateFaq = createAsyncThunk<ICreateFaqResponse, IUpdateFaqDTO, { serializedErrorType: IApiError }>(
  `${SLICE_NAME}/updateFaq`,
  async (data) => {
    const response = await FaqAPI.updateFaq(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const createFaq = createAsyncThunk<ICreateFaqResponse, ICreateFaqDTO, { serializedErrorType: IApiError }>(
  `${SLICE_NAME}/createFaq`,
  async (data) => {
    const response = await FaqAPI.createFaq(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const deleteFaq = createAsyncThunk<IGetFaqsResponse, IDeleteFaqDTO, { serializedErrorType: IApiError }>(
  `${SLICE_NAME}/deleteFaq`,
  async (data) => {
    const response = await FaqAPI.deleteFaq(data);
    return response.data;
  },
  { serializeError: formatApiError },
);
