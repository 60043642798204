import { useAppDispatch, useAppState } from '@/store';
import { useEffect, useMemo } from 'react';
import { getFaqs, selectFaqs, selectFaqsIsLoading, selectPagination } from '../feature';
import { selectChannel } from '@/modules/Channels';
import { GetFaqsDTO } from '@/modules/Faq/dtos';

export const useGetFaqs = () => {
  const dispatch = useAppDispatch();
  const channel = useAppState(selectChannel);
  const faqs = useAppState(selectFaqs);
  const isLoading = useAppState(selectFaqsIsLoading);
  const pagination = useAppState(selectPagination);

  useEffect(() => {
    if (!channel || !!faqs || isLoading) return;
    const dto = new GetFaqsDTO({ channelId: channel.id });
    dispatch(getFaqs(dto));
  }, [channel, dispatch, faqs, isLoading]);

  return useMemo(() => ({ faqs, isLoading, pagination }), [faqs, isLoading, pagination]);
};
