import { getChannelsOptions } from '@/modules/Channels';
import { RootState } from '@/store';
import { ISelectProps, Select } from '@/ui';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';

type ChannelsSelectProps = ISelectProps<number | number[] | string | string[], number | string> & {
  filterCurrentChannel?: boolean;
};

export const ChannelsSelect: FC<ChannelsSelectProps> = ({ filterCurrentChannel = true, value = [], ...props }) => {
  const currentChannel = useSelector((state: RootState) => state.channels.channel);
  const channels = useSelector((state: RootState) => state.channels.channels);
  const options = [{ value: 0, label: 'All channels' }, ...getChannelsOptions(channels)].filter((ch) =>
    filterCurrentChannel ? currentChannel?.id !== ch.value : true,
  );

  const [selectedChannels, setSelectedChannels] = useState<number[]>(value as number[]);

  const handleChannelChange = (selected: number[]) => {
    let updatedSelection = selected;

    if (selected.includes(0)) {
      updatedSelection = [0];
    }

    setSelectedChannels(updatedSelection);

    if (props.onChange) {
      props.onChange(updatedSelection);
    }
  };

  return (
    <Select
      mode="multiple"
      options={options}
      {...props}
      value={selectedChannels}
      disabled={selectedChannels.includes(0)}
      onChange={handleChannelChange}
    />
  );
};
