import { IGetBlogPostsOptionsDTO } from '@/models/blogPost.model';

export class GetBlogPostsDTO implements IGetBlogPostsOptionsDTO {
  pageSize: number; // pageSize should be a number

  page: number; // page should be a number

  search?: string; // search is optional, can be undefined

  channelSlug?: string; // channelSlug is optional

  withDrafts?: boolean; // withDrafts is optional, default can be set later

  injectVariables?: boolean; // injectVariables is optional, default can be set later

  constructor({
    pageSize,
    page,
    query,
  }: {
    pageSize?: number; // pageSize is a number
    page?: string | number; // page can be a string or number
    query?: string | null; // search query, can be null or string
    channelSlug?: string; // channelSlug, optional
    withDrafts?: boolean; // withDrafts flag, optional
    injectVariables?: boolean; // injectVariables flag, optional
  }) {
    this.pageSize = pageSize || 10; // Default to 10 if not provided
    this.page = typeof page === 'number' ? page : page ? parseInt(page, 10) : 1; // Default to 1 if page is not provided
    this.search = query || undefined;
    this.withDrafts = true;
    this.injectVariables = false;
  }
}
