import { ROUTES } from '@/constants/routes';
import { ProtectedRoute } from '@/modules/Auth';
import { AppError } from '@/modules/Errors';
import { MainLayout } from '@/modules/Layout';
import {
  AkeneoSync,
  Categories,
  Channels,
  Faqs,
  GeneralSettings,
  Metatags,
  Pages,
  Redirects,
  Reviews,
  RobotsTxt,
  ShippingPrices,
  SignIn,
  UISettings,
  Variables,
} from '@/pages';
import { Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import Blog from 'src/pages/blog';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path={ROUTES.SIGN_IN} element={<SignIn />} />
      <Route element={<ProtectedRoute />}>
        <Route element={<MainLayout />}>
          <Route errorElement={<AppError />}>
            <Route path={ROUTES.GENERAL_SETTINGS} element={<GeneralSettings />} />
            <Route path={ROUTES.UI_SETTINGS} element={<UISettings />} />
            <Route path={ROUTES.SHIPPING_PRICES} element={<ShippingPrices />} />
            <Route path={ROUTES.REVIEWS} element={<Reviews />} />
            <Route path={ROUTES.CHANNELS} element={<Channels />} />
            <Route path={ROUTES.ROBOTS_TXT} element={<RobotsTxt />} />
            <Route path={ROUTES.PAGES_MANAGEMENT} element={<Pages />} />
            <Route path={ROUTES.METATAGS_TEMPLATES} element={<Metatags />} />
            <Route path={ROUTES.REDIRECTS} element={<Redirects />} />
            <Route path={ROUTES.VARIABLES} element={<Variables />} />
            <Route path={ROUTES.AKENEO} element={<AkeneoSync />} />
            <Route path={ROUTES.FAQS} element={<Faqs />} />
            <Route path={ROUTES.CATEGORIES} element={<Categories />} />
            <Route path={ROUTES.BLOG} element={<Blog />} />
            <Route path="*" index element={<GeneralSettings />} />
          </Route>
        </Route>
      </Route>
      <Route path="*" element={<SignIn />} />
    </Route>,
  ),
);
