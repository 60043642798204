// Global
import { TableColumnsType } from 'antd';

// Models
import { IReview, ReviewStatus } from '@/models/review.model';

// Components
import Rate from 'src/shared/ui/Rate/Rate';
import { ReviewStatusTag } from '../components/ReviewStatusTag/ReviewStatusTag';
import { ProductLink } from '../components/ProductLink';

// Utils
import { renderDate } from 'src/shared/utils/dataRender';

export const reviewsTableColumns: TableColumnsType<IReview> = [
  {
    title: 'Date and time',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 180,
    render: (date: string) => renderDate(date, 'D MMM, hh:mm a'),
  },
  {
    title: 'Product link',
    dataIndex: ['channel', 'siteUrl'],
    key: 'link',
    ellipsis: true,
    render: (siteUrl: string, record: IReview) => <ProductLink siteUrl={siteUrl} productId={record.productId} />,
  },
  {
    title: 'Product ID',
    dataIndex: 'productId',
    key: 'productId',
  },
  {
    title: 'Comment',
    dataIndex: 'reviewText',
    key: 'reviewText',
    ellipsis: {
      showTitle: false,
    },
  },
  {
    title: 'Rating',
    dataIndex: 'overallRating',
    key: 'rating',
    width: 180,
    render: (rating: number) => <Rate disabled value={rating || 0} />,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: 160,
    render: (status: ReviewStatus.APPROVED | ReviewStatus.PENDING) => <ReviewStatusTag status={status} />,
  },
];
