import { useAppDispatch, useAppState } from '@/store';
import { useEffect } from 'react';

import { selectChannel } from '@/modules/Channels';
import { useSearchParams } from 'react-router-dom';
import { GetBlogPostsDTO } from '../dtos/GetBlogPosts.dto';
import {
  getBlogPosts,
  selectBlogPosts,
  selectBlogPostsError,
  selectBlogPostsIsLoading,
  selectBlogPostsPagination,
} from '../feature';

export const useBlogPosts = () => {
  const currentChannel = useAppState(selectChannel);
  const isLoading = useAppState(selectBlogPostsIsLoading);
  const blogPosts = useAppState(selectBlogPosts);
  const pagination = useAppState(selectBlogPostsPagination);
  const error = useAppState(selectBlogPostsError);
  const [searchQuery] = useSearchParams();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isLoading || error || !currentChannel?.id || blogPosts) return;
    const dto = new GetBlogPostsDTO({
      page: searchQuery.get('page') || 1,
      query: searchQuery.get('search') || undefined,
    });
    dispatch(getBlogPosts(dto)).unwrap();
  }, [dispatch, error, isLoading, blogPosts, searchQuery, currentChannel?.id]);

  return {
    isLoading,
    blogPosts,
    error,
    pagination,
    currentChannel,
  };
};
