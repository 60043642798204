import { IFaq } from '@/models/faq.model';
import { TFaqFormValues, FaqFieldsNames } from './types';
import { ICategory } from '@/models/category.model';

export const mapToFormValues = (faq: IFaq): TFaqFormValues => ({
  [FaqFieldsNames.ANSWER]: faq?.answer,
  [FaqFieldsNames.CATEGORY_CODES]: faq?.categories?.map((category) => category.code),
  [FaqFieldsNames.IS_ACTIVE]: faq?.isActive,
  [FaqFieldsNames.QUESTION]: faq?.question,
});

export const mapCategoriesTreeToArray = (categories: ICategory[]): ICategory[] => {
  const subCategories = categories?.flatMap(({ children }) => mapCategoriesTreeToArray(children || []));
  return [...categories, ...subCategories];
};
