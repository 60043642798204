// Globals
import { TableColumnsType } from 'antd';
import { TFunction } from 'i18next';

// Components
import { Button } from '@/ui';
import { StyledButtons, StyledTruncate } from '../components';

// Models
import { PenIcon, TrashIcon } from '@/icons';
import { BlogPostStatus, IBlogPost } from '@/models/blogPost.model';
import { renderDate } from '@/utils';

export const blogPostsColumns = (
  t: TFunction,
  onDelete?: (record: IBlogPost) => void,
  onEdit?: (record: IBlogPost) => void,
): TableColumnsType<IBlogPost> => [
  {
    dataIndex: 'title',
    key: 'title',
    width: 200,
    title: t('column_title'),
    render: (code: string) => <StyledTruncate $width="200">{code}</StyledTruncate>,
  },
  {
    dataIndex: 'slug',
    key: 'slug',
    width: 200,
    title: t('column_slug'),
    render: (source: string) => <StyledTruncate $width="200">{source}</StyledTruncate>,
  },
  {
    dataIndex: 'status',
    key: 'status',
    width: 200,
    title: t('column_status'),
    render: (target: string) => <StyledTruncate $width="200">{target}</StyledTruncate>,
  },
  {
    dataIndex: 'publishedAt',
    key: 'publishedAt',
    width: 150,
    title: t('column_published_at'),
    render: (date: string, record) => {
      if (record.status === BlogPostStatus.DRAFT) return t('label_not_visible');
      if (record.status === BlogPostStatus.PUBLISHED) return t('label_published');

      return renderDate(date, 'D MMM, hh:mm a');
    },
  },
  {
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    width: 150,
    title: t('column_updated_at'),
    render: (date: string) => renderDate(date, 'D MMM, hh:mm a'),
  },
  {
    title: t('column_action'),
    key: 'action',
    width: 150,
    render: (_, record) => (
      <StyledButtons>
        <Button
          icon={<PenIcon />}
          type="primary"
          size="small"
          block
          onClick={() => {
            onEdit?.(record);
          }}
        />
        <Button
          type="primary"
          size="small"
          icon={<TrashIcon />}
          danger
          block
          onClick={() => {
            onDelete?.(record);
          }}
        />
      </StyledButtons>
    ),
  },
];
