import { Button } from '@/ui';
import styled from 'styled-components';

export const StyledButtons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StyledButton = styled(Button)`
  margin-left: 10px;
`;
