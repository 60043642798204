import { IChannel } from '@/models/channel.model';
import { SyncType } from '../helpers/types';
import { TSelectOptions } from '@/ui';
import { TFunction } from 'i18next';
import { EntityTypes } from '@/models/akeneoSync.model';

export const getSyncOptions = (t: TFunction): TSelectOptions<string> => [
  { value: SyncType.PRODUCTS, label: t('products-sync') },
  { value: SyncType.PRODUCT_MODELS, label: t('products-models-sync') },
];

export const getChannelsOptions = (channels?: IChannel[] | null) =>
  (channels ?? []).map((channel) => ({
    value: channel.slug,
    label: channel.name,
  }));

export const getEntitiesOptions = (t: TFunction): TSelectOptions<EntityTypes> => [
  { value: EntityTypes.ASSOCIATION_TYPES, label: t('association_types') },
  { value: EntityTypes.ATTRIBUTES, label: t('attributes') },
  { value: EntityTypes.CATEGORIES, label: t('categories') },
  { value: EntityTypes.MEASUREMENTS, label: t('measurements') },
];
