import { CategoryAPI } from '@/api';
import { IApiError } from '@/models/apiError.model';
import {
  IGetCategoriesDTO,
  IGetCategoriesResponse,
  IGetCategoriesTreeDTO,
  IUpdateCategoryDTO,
} from '@/models/category.model';
import { formatApiError } from '@/utils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { SLICE_NAME } from './models';

export const getCategories = createAsyncThunk<
  IGetCategoriesResponse,
  IGetCategoriesDTO,
  { serializedErrorType: IApiError }
>(
  `${SLICE_NAME}/getCategories`,
  async (params) => {
    const response = await CategoryAPI.getCategories(params);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const getCategoriesTree = createAsyncThunk<
  IGetCategoriesResponse,
  IGetCategoriesTreeDTO,
  { serializedErrorType: IApiError }
>(
  `${SLICE_NAME}/getCategoriesTree`,
  async (params) => {
    const response = await CategoryAPI.getCatagoriesTree(params);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const updateCategory = createAsyncThunk<
  IGetCategoriesResponse,
  IUpdateCategoryDTO,
  { serializedErrorType: IApiError }
>(
  `${SLICE_NAME}/updateCategory`,
  async (data) => {
    const response = await CategoryAPI.updateCategory(data);
    return response.data;
  },
  { serializeError: formatApiError },
);
