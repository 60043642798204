import { Form, FormItem } from '@/components';
import { COLORS, pickThemeFontStyles } from '@/theme';
import styled from 'styled-components';

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
  height: 100%;
`;

export const StyledCheckboxFormItem = styled(FormItem)`
  margin-bottom: 1rem;

  .ant-row {
    display: inline-flex;
    gap: 1rem;
    align-items: center;
    flex-direction: row-reverse;

    label::after {
      display: none;
    }
  }
`;

export const StyledFormItem = styled(FormItem)`
  margin-bottom: 1rem;
`;

export const StyledSubtitle = styled.div`
  ${pickThemeFontStyles('14', '22', 'SEMI_BOLD')};
  color: ${COLORS.BLACK};
  margin-bottom: 1rem;
`;

export const StyledFormListItem = styled(FormItem)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1rem 0;
`;
